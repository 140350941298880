<template>
  <div class="lesson_detail project_detail">
    <div class="detail_wrapper manage-wrapper">
      <div class="tabs__header detail-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="课节详情" name="detail">
            <el-form ref="form" :model="form" label-width="180px">
              <el-form-item label="沙龙课节名称：" :required="true">
                <p>{{ form.lessonsName || '无'}}</p>
              </el-form-item>
              <el-form-item label="上课时间：" :required="true">
                <p>
                  {{ dayjs(form.lessonsBeginTime*1000).format('YYYY-MM-DD HH:mm:ss') }} -
                  {{ dayjs(form.lessonsEndTime*1000).format('HH:mm:ss') }}
                </p>
              </el-form-item>

              <div class="teahcers flex-align">
                <el-form-item label="授课老师：" :required="true">
                  <p>{{ form.teacherName || '无'  }}</p>
                </el-form-item>
                <el-form-item label="助教老师：">
                  <p>{{ form.assistantName || '无' }}</p>
                </el-form-item>
              </div>

              <el-form-item label="参训用户：" :required="true">
                <p>{{ Number(form.lessonsPattern) ?'按用户组':'按人选' }}</p>
                <div v-if="Number(form.lessonsPattern)">
                  <!-- <UserGroup :id="Number(currentProject)" @userGroup="changeUserGroup"></UserGroup> -->
                  <el-cascader :options="groupInfo" v-model="form.userOrGroupIds"
                    :props="{ multiple: true , value: 'id',emitPath:false}" disabled></el-cascader>
                </div>
                <div v-else>
                  <el-transfer v-model="form.userOrGroupIds" :data="userList" :titles="['全部用户', '已选用户']"
                    :button-texts="['取消','选择']" filterable filter-placeholder="按姓名或手机号" :filter-method="filterMethod"
                    :props="{key:'id',label:'name',disabled :'disabled'}" disabled>
                    <span slot-scope="{ option }">{{ option.name }} - {{ option.phone }}</span>
                  </el-transfer>
                </div>

              </el-form-item>




              <el-form-item label="直播课件：" required>
                <p v-if="!form.liveFileNames">无</p>
                <ul class="zhibo_list" v-if="form.liveFileNames">
                  <li style="" v-for="(item, index) in form.liveFileNames" :key="index" class="csp">
                    <span @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>

              <el-form-item label="课节附件：">
                <p v-if="!form.lessonFileNames">无</p>
                <ul class="zhibo_list" v-if="form.lessonFileNames">
                  <li class="csp" v-for="(item, index) in form.lessonFileNames" :key="index" @click="openFile(item)">
                    <span @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>

              <el-form-item :label="'回放视频：'">
                <p v-if="!form.videoNames">无</p>
                <ul class="zhibo_list el-col el-col-20" v-if="form.videoNames">
                  <li class="csp" style="" v-for="(item, index) in form.videoNames" :key="index">
                    <span @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>

              <el-form-item class="el-form-item-btns" label-width="0px">
                <el-button type="primary" @click="goEdit">编辑</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        activeName: 'detail',
        form: {},
        userList: [],
        groupInfo:[],
      };
    },
    computed: {
      ...mapState({
        currentProject: state => state.user.currentProject.id,
      })
    },
    methods: {
      // 去编辑页
      goEdit() {
        let id = this.$route.params.id;
        this.$router.push({
          path: `/salon/lessons/edit/${id}`,
          query: {
            breadNumber: 5,
          }
        });
      },
      // 请求课节信息
      async getLessonInfo() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.getSalonLessonDetail(id);
        console.log('沙龙课节详情', resData.data);
        if (!resData) return
        this.form = resData.data;
        this.getGroupInfo();
        if (this.form.vodName) {
          let arr = [];
          arr.push(this.form.vodName)
          this.form.vodName = arr
        }
        // console.log(this.form);
      },
      //获取用户列表 （按人选）
      async getUserList() {
        this.list = [];
        let params = {
          projectId: this.currentProject,
        };
        console.log(params);
        let resData = await this.$Api.Course.getSalonTeacher(params);
        console.log('人员列表', resData);
        this.userList = resData.data;
        this.userList.forEach((item) => {
          item.disabled = true;
        })
      },
      // 人员筛选
      filterMethod(query, item) {
        let str = String(item.phone)
        if (item.name.indexOf(query) > -1) {
          return item.name.indexOf(query) > -1;
        } else if (str.indexOf(query) > -1) {
          return str.indexOf(query) > -1
        } else {
          return
        }
      },
      // 切换用户组
      changeUserGroup(data) {
        console.log(data);
        this.form.trancheId = data.trancheId;
        this.form.projectId = data.projectId;
        this.form.userOrGroupIds = data.userGroupArr;
        this.form.userGroupIds = data.userGroupArr;
        // console.log(this.form);
      },
      // 获取用户组选项
      async getGroupInfo() {
        let id = this.form.projectId;
        let resData = await this.$Api.Course.GroupInfo(id);
        this.groupInfo = resData.data;
      },
      // 预览、下载文件
      openFile(item) {
        window.open(this.downloadURL + item.identifier)
      },
      // 页面初始化
      init() {
        window.scroll(0, 0)
        this.getLessonInfo();
        this.getUserList();
      },

    },
    mounted() {
      this.init();
    },
    activated() {
      this.init();
    }
  };
</script>

<style lang="less" scoped>
 @import "../../manage_project/less/manage-project.less";
  @import "../../manage_project/less/lesson_commen.less";

  .lesson_detail {
    .el-form-item {
      margin-bottom: 15px;
    }

    .cover {
      margin-top: 10px;
      display: block;
      width: 193px;
      height: 125px;
      background-color: palevioletred;
    }

    .teahcers .el-form-item:last-child {
      margin-left: 130px;
    }

    /deep/ .el-form-item-btns {
      margin: 52px 0 34px;

      .el-button {
        margin-left: 0px;
        font-size: 13px;
        line-height: 31px;
        padding: 0 47px;
        color: #fff;
        background-color: #508EF9;
      }

      @media screen and (min-width:1250px) {
        .el-button {
          font-size: 16px;
          line-height: 38px;
          padding: 0 57px;
        }
      }
    }

    .zhibo_list {
      li {
        display: inline-block;
        line-height: 32px;

        margin: 13px 20px 5px 0;
        border-radius: 4px;
        padding: 0px 12px;

        color: #666;
        border: 1px solid rgba(204, 204, 204, .22);

        .deletefile {
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }


  }
</style>